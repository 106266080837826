$(function(){
  // MAIN
  $('body .panel-content-menu-nav-bottom').on('click',function(e){
    let icon = $(this).find('.icone-f').text();
    if(icon == 'keyboard_double_arrow_left'){
      $('.nav-sidebar').css('left','-250px');
      $('.main-container').css({'left':'0','width':'100%'});
      $(this).find('.icone-f').text('keyboard_double_arrow_right');
      $('.content-vhb').css({'left':'50%','width':'100vw','margin-left':'-50vw'});
    }
    else{
      $('.nav-sidebar').css('left','0');
      $('.main-container').css({'left':'250px','width':'calc(100% - 250px)'});
      $(this).find('.icone-f').text('keyboard_double_arrow_left');
      $('.content-vhb').css({'left':'0','width':'100%', 'margin-left': '0'});
    }
  });

  $('#logout-click').on('click',function(e){
    e.preventDefault();
    document.getElementById('logout-form').submit();
  });
});
